export const SET_SCROLL_ELEMENT = 'SET_SCROLL_ELEMENT'

export default function reducer(
    state = {
        server: '',
        client: '',
        scrollToElement: null
    },
    action
) {
    switch (action.type) {
        case SET_SCROLL_ELEMENT:
            return { ...state, scrollToElement: action.payload }

        default:
            return state
    }
}
