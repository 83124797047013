export const SET_USER_LOGIN = 'setUserLogin'
export const SET_USER_DATA = 'setUserData'
export const SET_USER_TOKEN = 'setUserToken'
export const SET_USER_LOGIN_SESSION = 'SET_USER_LOGIN_SESSION'
export const SET_USER_ID = 'SET_USER_ID'
export const SET_USER_LOGOUT = 'SET_USER_LOGOUT'
export const SET_USER_RESET = 'SET_USER_RESET'

export default function reducer(
    state = {
        server: '',
        client: '',
        token: '',
        loggedIn: false,
        user: {
            id: null,
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        },
        reset: false
    },
    action
) {
    switch (action.type) {
        case SET_USER_LOGIN:
            return {
                ...state,
                user: {
                    email: action.payload.email,
                    password: action.payload.password
                }
            }
        case SET_USER_DATA:
            return {
                ...state,
                user: {
                    ...state.user,
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                    email: action.payload.email,
                    phone: action.payload.phone
                }
            }
        case SET_USER_LOGIN_SESSION:
            return {
                ...state,
                loggedIn: action.payload
            }
        case SET_USER_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        case SET_USER_ID:
            return {
                ...state,
                user: {
                    id: action.payload
                }
            }
        case SET_USER_LOGOUT:
            return {
                ...state,
                token: '',
                loggedIn: false,
                user: {
                    id: null,
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: ''
                }
            }
        case SET_USER_RESET:
            return {
                ...state,
                reset: action.payload
            }
        default:
            return state
    }
}
