import moment from 'moment'
import { POST_PAID_PAYMENT_METHODS } from '@/constants/constants'

export function transformDay(weekday) {
    switch (weekday) {
        case 'MONDAY':
            return 'Pondělí'
        case 'TUESDAY':
            return 'Úterý'
        case 'WEDNESDAY':
            return 'Středa'
        case 'THURSDAY':
            return 'Čtvrtek'
        case 'FRIDAY':
            return 'Pátek'
        case 'SATURDAY':
            return 'Sobota'
        case 'SUNDAY':
            return 'Neděle'
        default:
            return weekday
    }
}

export function transformOpeninHourTime(time) {
    return moment(time, 'HH:mm:ss').format('HH:mm')
}

export function transformHourMinute(time) {
    return moment(time).format('HH:mm')
}

export function transformDayHour(time) {
    return moment(time).format('DD.MM. v HH:mm')
}

export function transformToMinutes(time) {
    const now = moment().startOf('day')
    const endTime = moment(time)
    const duration = moment.duration(endTime.diff(now))

    return parseInt(duration.asMinutes())
}

export function webalizeName(name) {
    return name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .split(' ')
        .join('-')
}

export function webalizeBranchName(name) {
    let formattedName = name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .split(' ')
        .join('-')
    // formattedName = formattedName.substring(1, formattedName.length)
    // if (formattedName.charAt(formattedName.length - 1) === '-')
    //     formattedName = formattedName.substring(0, formattedName.length - 1)
    return formattedName
}

function getRGB(c) {
    return parseInt(c, 16) || c
}

function getsRGB(c) {
    return getRGB(c) / 255 <= 0.03928 ? getRGB(c) / 255 / 12.92 : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4)
}

function getLuminance(hexColor) {
    return (
        0.2126 * getsRGB(hexColor.substr(1, 2)) +
        0.7152 * getsRGB(hexColor.substr(3, 2)) +
        0.0722 * getsRGB(hexColor.substr(-2))
    )
}

function getContrast(f, b) {
    const L1 = getLuminance(f)
    const L2 = getLuminance(b)
    return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
}

export function getTextColor(bgColor) {
    const whiteContrast = getContrast(bgColor, '#ffffff')
    const blackContrast = getContrast(bgColor, '#000000')

    return whiteContrast > blackContrast ? '#ffffff' : '#000000'
}

export const getColorFromRecipeTags = product => {
    let color = null
    if (product.recipeTags && product.recipeTags.length !== 0) {
        color = product.recipeTags[0].color
    } else {
        return color
    }
    return color
}

export function isCategoryNotEmpty(categories) {
    /* let isEmpty = false
    if (arrayToCheck !== undefined && arrayToCheck && arrayToCheck.length !== 0) {
      isEmpty =
        arrayToCheck.map(item => {
          return item.recipes.length !== 0
        }).length !== 0
    }
    return isEmpty*/
    let shouldRenderCategory

    if (categories && categories.length !== 0) {
        shouldRenderCategory = !categories.every(category => category.recipes.length === 0)
    } else shouldRenderCategory = false
    return shouldRenderCategory
}

export const isPostPaidMethod = paymentMethodGateEnum => {
    return POST_PAID_PAYMENT_METHODS.includes(paymentMethodGateEnum)
}
