export const INCREMENT_COUNTER = 'incrementCounter'
export const DECREMENT_COUNTER = 'decrementCounter'

export default function reducer(
    state = {
        server: '',
        client: '',
        counter: 0
    },
    action
) {
    switch (action.type) {
        case INCREMENT_COUNTER:
            return { ...state, counter: action.payload }
        case DECREMENT_COUNTER:
            return { ...state, counter: action.payload }
        default:
            return state
    }
}
