import { SET_PRODUCT_ADDONS, SET_PRODUCT_INFO } from '../reducers/modalReducer'

export const setProductAddons = addons => dispatch => {
    return dispatch({
        type: SET_PRODUCT_ADDONS,
        payload: addons
    })
}
export const setProductInfo = productInfo => dispatch => {
    return dispatch({
        type: SET_PRODUCT_INFO,
        payload: productInfo
    })
}
