import { callGraphQlClient } from '../loaderGraphQl'
import { BRAND_ID, DeliveryTypeEnum, WarePriceTypeEnum } from '@/constants/constants'
import { updateOrderData } from '@/graphql/mutation/updateOrderData'
import { changeDeliveryType, setOrderData, setOrderId, updateNumber, updateReduxTip } from '@/redux/actions/orderAction'
import { webalizeBranchName } from '@/functions/common/helper'
import { submitOrderMutation } from '@/graphql/mutation/submitOrderMutation'
import { setProductInfo } from '@/redux/actions/modalAction'
import { encode } from 'js-base64'
import { checkCookies, getCookie } from 'cookies-next'
import { createPickupOrder } from '@/graphql/mutation/createPickupOrder'
import { createDeliveryOrder } from '@/graphql/mutation/createDeliveryOrder'
import { captureException } from '@sentry/nextjs'

export const changeDeliveryData = (dispatch, data) => {
    dispatch(changeDeliveryType(WarePriceTypeEnum.DELIVERY))

    const { resolveLocation } = data

    const orderData = {
        id: resolveLocation.companyBranch.id,
        city: resolveLocation.companyBranch.address.location.city,
        url: webalizeBranchName(resolveLocation.companyBranch.address.location.city),
        gps: resolveLocation.gpsCoordinates,
        formattedAddress: resolveLocation.finalAddressFormatted,
        unFormattedAddress: data.unFormattedAddress,
        zoneId: resolveLocation.zone.id,
        zoneInterval: resolveLocation.zone.deliveryTimeDeviation
    }
    dispatch(setOrderData(orderData))
}

// TODO: Update only if clicked branch or selected delivery food
export const transferOrder = (orderId, companyBranch, gps, address, deliveryType, dispatch) => {
    const variables = {
        id: orderId,
        companyBranch: companyBranch,
        gps: gps,
        address: address,
        deliveryType: deliveryType === WarePriceTypeEnum.INHOUSE ? DeliveryTypeEnum.PICKUP : DeliveryTypeEnum.MESSENGER
    }

    callGraphQlClient(updateOrderData, variables, dispatch)
        .then(() => {
            dispatch(updateNumber(Math.random()))
        })
        .catch(err => {
            captureException(err)
        })
}

export const submitOrder = (
    orderId,
    values,
    userData,
    note,
    paymentMethodId,
    vamStatus,
    returnURL,
    cancelURL,
    dispatch
) => {
    const InputEmailCreate = {
        description: '',
        email: values.email
    }
    const InputPhoneCreate = {
        description: '',
        phone: values.phone
    }
    const vamStatuses = []
    vamStatus.map(item => {
        vamStatuses.push(parseInt(item.id))
    })
    const variables = {
        orderId: orderId,
        firstName: values.firstName,
        lastName: values.lastName,
        emails: [InputEmailCreate],
        phones: [InputPhoneCreate],
        deliverySlotId: userData.deliverySlot.id ? userData.deliverySlot.id : '',
        note: note,
        paymentMethodId: paymentMethodId,
        returnUrl: returnURL,
        cancelUrl: cancelURL,
        confirmedAgreements: vamStatuses
    }
    return callGraphQlClient(submitOrderMutation, variables, dispatch)
}

export const resetOrderAndCreateNew = dispatch => {
    dispatch(setOrderData(null))
    dispatch(
        setProductInfo({
            wareCategoryId: null,
            promoTag: null
        })
    )
    dispatch(
        updateReduxTip({
            tip: 0,
            tipSelected: false
        })
    )
    dispatch(setOrderId(null))
    dispatch(changeDeliveryType(WarePriceTypeEnum.INHOUSE))
    dispatch(updateNumber(Math.random()))
}

export const setupPaymentFinalScreen = (router, orderId, verification, savedBranch) => {
    let branch = ''
    if (router.query.branch !== undefined) branch = router.query.branch
    else branch = savedBranch

    let token = ''
    if (checkCookies('token')) {
        token = encode(getCookie('token'))
    }

    if (router.query.userToken !== undefined) token = router.query.userToken

    const baseURL = process.env.NEXT_PUBLIC_APP_WEB_URL
    const nextPage = verification ? 'verifikace' : 'dekujeme'

    return `${baseURL}/${branch}/${nextPage}?orderId=${orderId}&userToken=${token}`
}

export async function createOrder(orderId, deliveryType, orderData, dispatch) {
    if ((orderId === null || orderId === undefined) && orderData && orderData.id) {
        let newOrderId
        if (deliveryType.text === WarePriceTypeEnum.INHOUSE) {
            const variables = {
                branchId: orderData.id
            }

            const speedloData = await callGraphQlClient(createPickupOrder, variables, dispatch).catch(err => {
                // captureException(err);
                console.error(err)
            })

            newOrderId = speedloData.createPickupOrder.id
        } else {
            const variables = {
                branchId: orderData.id,
                gps: orderData.gps,
                address: orderData.unFormattedAddress,
                brandId: BRAND_ID
            }
            const speedloData = await callGraphQlClient(createDeliveryOrder, variables, dispatch).catch(err => {
                // captureException(err)
                console.error(err)
            })

            newOrderId = speedloData.createDeliveryOrder.id
        }
        dispatch(setOrderId(newOrderId))
        dispatch(updateNumber(Math.random()))
        return true
    }
    return false
}
