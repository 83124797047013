export const SET_CURRENT_TAG = 'setCurrentTag'

export default function reducer(
    state = {
        server: '',
        client: '',
        currentTag: ''
    },
    action
) {
    switch (action.type) {
        case SET_CURRENT_TAG:
            return { ...state, currentTag: action.payload }
        default:
            return state
    }
}
