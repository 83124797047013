import { WarePriceTypeEnum } from '@/constants/constants'

export const CHANGE_DELIVERY_TYPE = 'changeDeliveryType'
export const SET_ORDER_DATA = 'setOrderData'
export const SET_BACKUP_ORDER_DATA = 'setBackupOrderData'
export const SET_ORDER_LATER_CLOSED_BRANCH = 'setOrderLaterClosedBranch'
export const SET_ORDER_ID = 'setOrderId'
export const UPDATE_NUMBER = 'updateNumber'
export const UPDATE_TOKEN_EXPIRATION = 'updateTokenExpiration'
export const SET_TIP = 'setTip'
export const SET_CURRENT_PRODUCT = 'setCurrentProduct'

export default function reducer(
    state = {
        server: '',
        client: '',
        id: null,
        deliveryType: {
            type: 'INHOUSE',
            text: WarePriceTypeEnum.INHOUSE
        },
        updateNumber: 0,
        tokenExpiration: 0,
        branchClosedOrderOnLaterTime: false,
        orderData: {
            id: null,
            city: null,
            url: null,
            gps: null,
            formattedAddress: null,
            unFormattedAddress: null,
            zoneId: null,
            zoneInterval: null
        },
        backupOrderData: {
            id: null,
            city: null,
            url: null,
            gps: null,
            formattedAddress: null,
            unFormattedAddress: null,
            zoneId: null,
            zoneInterval: null
        },
        currentProductSelected: null,
        tip: 0,
        tipSelected: false
    },
    action
) {
    switch (action.type) {
        case CHANGE_DELIVERY_TYPE:
            return { ...state, deliveryType: action.payload }
        case SET_ORDER_DATA:
            return { ...state, orderData: action.payload }
        case SET_BACKUP_ORDER_DATA:
            return { ...state, backupOrderData: action.payload }
        case SET_ORDER_LATER_CLOSED_BRANCH:
            return { ...state, branchClosedOrderOnLaterTime: action.payload }

        case SET_ORDER_ID:
            return { ...state, id: action.payload }
        case UPDATE_NUMBER:
            return { ...state, updateNumber: action.payload }
        case UPDATE_TOKEN_EXPIRATION:
            return { ...state, tokenExpiration: action.payload }
        case SET_TIP:
            return { ...state, tip: action.payload.tip, tipSelected: action.payload.tipSelected }
        case SET_CURRENT_PRODUCT:
            return { ...state, currentProductSelected: action.payload }

        default:
            return state
    }
}
