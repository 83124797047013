import { gql } from 'graphql-request'

export const productPrice = gql`
    fragment productPrice on PriceConverted {
        currency {
            enum
            code
        }
        value
    }
`

export const productDetail = gql`
    fragment productDetail on Recipe {
        id
        nameLabel
        image
        imagePreview: image(width: 200, height: 200)
        descriptionLabel
        allergens {
            category
        }
        associatedRecipes {
            id
            recipe {
                id
                nameLabel
                price {
                    ...productPrice
                }
            }
        }
        price {
            ...productPrice
        }
    }
    ${productPrice}
`
