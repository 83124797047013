export const SET_MENU = 'setMenu'

export default function reducer(
    state = {
        server: '',
        client: '',
        menu: null
    },
    action
) {
    switch (action.type) {
        case SET_MENU:
            return { ...state, menu: action.payload }
        default:
            return state
    }
}
