import axios from 'axios'
import { GraphQLClient } from 'graphql-request'
import { checkCookies, getCookie, setCookies } from 'cookies-next'
import { captureException } from '@sentry/nextjs'
import { resetOrderAndCreateNew } from '@/functions/orderControl/orderControl'
import { setUserId, setUserLoginSession, setUserToken } from '@/redux/actions/userAction'
import qs from 'qs-lite'

const env = process.env.NEXT_PUBLIC_APP_ENV || 'development'

//DEV
//https://app.stage.speedlo.cloud/graphql

//PROD
//https://c2e-p1.deep-vision.cloud/graphql

export const clientEndpoint =
    env === 'development' ? 'https://app.stage.speedlo.cloud/graphql' : 'https://c2e-p1.deep-vision.cloud/graphql'

// eslint-disable-next-line no-unused-vars
const PROD_TOKEN =
    'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo4MDQsImF1dGhlbnRpY2F0aW9uX3Rva2VuIjoiN3RrQTZrZjZMSFkwaTBBSkxxU25QZ3R0IiwiaWF0IjoyMTU3NDA5OTUsImp0aSI6IjE5YjI4NWZkYTY3NTQyZmE4ZWU4ZjA4N2NjYTA4Yjg1In0.v0h53HVm_AWb5zSo72wXmSHC4TihuDVoq0bEbgfvCng'

export const graphQLClient = async (dispatch, email = null, password = null, loginUser = false) => {
    let token = null
    if (checkCookies('token')) token = getCookie('token')
    if (token === null || token === undefined) {
        console.error('Token expired')
        if (dispatch) resetOrderAndCreateNew(dispatch)
        let speedloUserToken
        let userData
        if (loginUser) {
            userData = await getUserToken(email, password)
            speedloUserToken = userData.token
        } else {
            speedloUserToken = await getUserGuestToken()
        }
        if (speedloUserToken) {
            // let expiration = 36000 // 10h hours expiration for token
            if (!checkCookies('token')) {
                setCookies('token', speedloUserToken, { secure: false })
                // localStorage.setItem('tokenExpiration', moment().add(expiration, 'seconds'))
            }
            if (loginUser) {
                dispatch(setUserLoginSession(true))
                dispatch(setUserId(userData.userId))
                dispatch(setUserToken(speedloUserToken))
            }

            return new GraphQLClient(clientEndpoint, {
                headers: {
                    Authorization: 'Bearer ' + speedloUserToken,
                    'X-User-Language': 'cs'
                }
            })
        }
    } else {
        if (loginUser) {
            const userData = await getUserToken(email, password)
            token = userData.token
            setCookies('token', token, { secure: false })

            dispatch(setUserLoginSession(true))
            dispatch(setUserId(userData.userId))
            dispatch(setUserToken(token))
        }
        // TODO oprava
        return new GraphQLClient(clientEndpoint, {
            headers: {
                Authorization: 'Bearer ' + token,
                'X-User-Language': 'cs'
            }
        })
    }
}

// PROD
// https://c2e-p1.deep-vision.cloud/users/token?guest=true

//DEV
// https://app.dev.speedlo.cloud/users/token?guest=true

// PROD
// https://c2e-p1.deep-vision.cloud/users/token?username=rucnik@rucnik.rr&password=YmE5MmZhMjRhODdhNWMyZjhhNTIzZDVj

export const getUserGuestToken = async () => {
    const env = process.env.NEXT_PUBLIC_APP_ENV || 'development'

    const clientEndpoint =
        env === 'development'
            ? 'https://app.stage.speedlo.cloud/users/token?guest=true'
            : 'https://c2e-p1.deep-vision.cloud/users/token?guest=true'

    return axios
        .get(clientEndpoint)
        .then(res => {
            console.debug(res)
            if (res.data != null) return res.data.token
        })
        .catch(err => {
            captureException(err)
            console.error(err)
        })
}

export const getUserToken = async (email, password) => {
    const env = process.env.NEXT_PUBLIC_APP_ENV || 'development'

    qs.stringify({ username: email, password: password })
    console.info(password)
    const clientEndpoint =
        env === 'development'
            ? `https://app.stage.speedlo.cloud/users/customer_token?${qs.stringify({
                  username: email,
                  password: password
              })}`
            : `https://c2e-p1.deep-vision.cloud/users/customer_token?${qs.stringify({
                  username: email,
                  password: password
              })}`

    return axios.get(clientEndpoint).then(res => {
        if (res.data != null) {
            return {
                token: res.data.token,
                userId: res.data.userId
            }
        }
    })
}

export const resetUserPassword = async (returnUrl, login) => {
    const env = process.env.NEXT_PUBLIC_APP_ENV || 'development'

    const clientEndpoint =
        env === 'development'
            ? `https://app.stage.speedlo.cloud/users/reset_customer_password?return_url=${returnUrl}&login=${login}`
            : `https://c2e-p1.deep-vision.cloud/users/reset_customer_password?return_url=${returnUrl}&login=${login}`

    return axios.get(clientEndpoint).then(res => {
        console.info(res.data)
        return res
    })
}
