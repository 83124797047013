import {
    CHANGE_DELIVERY_TYPE,
    SET_BACKUP_ORDER_DATA,
    SET_CURRENT_PRODUCT,
    SET_ORDER_DATA,
    SET_ORDER_ID,
    SET_ORDER_LATER_CLOSED_BRANCH,
    SET_TIP,
    UPDATE_NUMBER,
    UPDATE_TOKEN_EXPIRATION
} from '../reducers/orderReducer'
import { WarePriceTypeEnum } from '@/constants/constants'

export const changeDeliveryType = newDeliveryType => dispatch => {
    const deliveryType = {
        type: newDeliveryType === WarePriceTypeEnum.DELIVERY ? 'DELIVERY' : 'INHOUSE',
        text: newDeliveryType
    }
    return dispatch({
        type: CHANGE_DELIVERY_TYPE,
        payload: deliveryType
    })
}

export const setOrderData = orderData => dispatch => {
    return dispatch({
        type: SET_ORDER_DATA,
        payload: orderData
    })
}

export const setBackupOldData = orderData => dispatch => {
    return dispatch({
        type: SET_BACKUP_ORDER_DATA,
        payload: orderData
    })
}

export const setOrderLaterClosedBranch = dontPopUpClosedDialog => dispatch => {
    return dispatch({
        type: SET_ORDER_LATER_CLOSED_BRANCH,
        payload: dontPopUpClosedDialog
    })
}

export const setOrderId = orderId => dispatch => {
    return dispatch({
        type: SET_ORDER_ID,
        payload: orderId
    })
}

export const updateNumber = data => dispatch => {
    return dispatch({
        type: UPDATE_NUMBER,
        payload: data
    })
}

export const updateTokenExpiration = data => dispatch => {
    return dispatch({
        type: UPDATE_TOKEN_EXPIRATION,
        payload: data
    })
}
export const updateReduxTip = data => dispatch => {
    return dispatch({
        type: SET_TIP,
        payload: data
    })
}
export const updateSelectedProduct = data => dispatch => {
    return dispatch({
        type: SET_CURRENT_PRODUCT,
        payload: data
    })
}
