import Image from 'next/image'

const PageLoader = () => {
    return (
        <div className={'flex justify-center bg-turbo-background h-screen'}>
            <Image priority={true} src={'/TURBO_Logo_RGB.svg'} alt="my gif" height={300} width={300} />
        </div>
    )
}
export default PageLoader
