import { useEffect } from 'react'

export default function CookieConsen() {
    useEffect(async () => {
        const logo = 'Nastavení cookies'
        const cookie = '🍪 '

        const Fuse = await import('./cookies.js')

        const cc = window.initCookieConsent()

        cc.run({
            languages: {
                en: {
                    consent_modal: {
                        title: cookie + ' Používáme cookies!',
                        description:
                            'Tyto webové stránky používají k poskytování služeb, personalizaci reklam a analýze návštěvnosti soubory cookies. Některé z nich jsou k fungování stránky nezbytné, ale o některých můžete rozhodnout sami. Více o používání souborů cookies se dozvíte níže. Můžete je povolit všechny, jednotlivě vybrat nebo všechny odmítnout. Více informací získáte kdykoliv na stránce Zásady používání souborů cookies.. <button type="button" data-cc="c-settings" class="cc-link">Nastavení cookies</button>',
                        primary_btn: {
                            text: 'Potvrdit vše',
                            role: 'accept_all' // 'accept_selected' or 'accept_all'
                        },
                        secondary_btn: {
                            text: 'Zakázat vše',
                            role: 'accept_necessary' // 'settings' or 'accept_necessary'
                        }
                    },
                    settings_modal: {
                        title: cookie + ' Používáme cookies!',
                        save_settings_btn: 'Uložit nastavení',
                        accept_all_btn: 'Potvrdit vše',
                        reject_all_btn: 'Zakázat vše',
                        close_btn_label: 'Zavřít',
                        cookie_table_headers: [
                            { col1: 'Name' },
                            { col2: 'Domain' },
                            { col3: 'Expiration' },
                            { col4: 'Description' }
                        ],
                        blocks: [
                            {
                                title: 'Používání cookies 📢',
                                description:
                                    'Tyto webové stránky používají k poskytování služeb, personalizaci reklam a analýze návštěvnosti soubory cookies. Některé z nich jsou k fungování stránky nezbytné, ale o některých můžete rozhodnout sami.'
                            },
                            {
                                title: 'Funkční cookies',
                                description:
                                    'Webová stránka nemůže správně fungovat bez těchto cookies, a proto jsou vždy povolené.',
                                toggle: {
                                    value: 'necessary',
                                    enabled: true,
                                    readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                                }
                            },
                            {
                                title: 'Statistické cookies',
                                description:
                                    'Statistické cookies pomáhají majitelům webových stránek, aby porozuměli, jak návštěvníci používají webové stránky. Anonymně sbírají a sdělují informace, které pomáhají k vylepšování obsahu stránek.',
                                toggle: {
                                    value: 'analytics', // there are no default categories => you specify them
                                    enabled: false,
                                    readonly: false
                                }
                            },
                            {
                                title: 'Sociální média',
                                description:
                                    'Se souhlasem cookies sociálních médií se můžete připojit k vašim sociálním sítím a prostřednictvím nich sdílet obsah z naší webové stránky. Při vypnutí se nebude zobrazovat obsah ze sociálních sítí (Facebook, Twitter, Youtube a další).',
                                toggle: {
                                    value: 'targeting',
                                    enabled: false,
                                    readonly: false
                                }
                            }
                        ]
                    }
                }
            }
        })
    }, [])

    return null
}
