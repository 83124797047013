export const BRAND_ID = 944

export const DAY_MONTH_FORMAT = 'DD.MM'
export const DAY_HOUR_FORMAT = 'HH:mm'

export const WarePriceTypeEnum = {
    DELIVERY: 'CHCI DOVÉZT',
    INHOUSE: 'VYZVEDNU SI'
}
export const POST_PAID_PAYMENT_METHODS = ['CASH', 'CREDIT']
export const PRE_PAID_PAYMENT_METHODS = ['CSOB', 'PAYU', 'GOPAY', 'PAY_FAST', 'GP_WEBPAY']

export const DeliveryTypeEnum = {
    PICKUP: 'PICKUP',
    MESSENGER: 'MESSENGER'
}

export const AddonsCategoryTypeEnum = {
    AMOUNT: 'amount',
    RADIO: 'radio',
    CHECKBOX: 'checkbox'
}

export const BannerTypeEnum = {
    HP_TOP: 'HomepageTop',
    HP_UNDER_SEARCH: 'HomepageUnderSearch',
    HP_BOTTOM: 'HomepageBottom',
    BRANCH_TOP: 'BranchTop',
    BRANCH_CATEGORY: 'BranchCategory',
    BRANCH_BOTTOM: 'BranchBottom'
}

export const FooterTypeEnum = {
    HP_BRAND: 'HomepageBrand',
    HP_BRANCH: 'HomepageBranch'
}
