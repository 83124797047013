import { gql } from 'graphql-request'

export const updateOrderData = gql`
    mutation updateOrderData(
        $id: ID!
        $companyBranch: ID
        $gps: InputGeoPointCreate
        $address: String
        #$note: String
        $deliverySlot: ID
        $deliveryType: DeliveryTypeEnum
    ) {
        updateOrder(
            id: $id
            order: {
                companyBranch: $companyBranch
                gps: $gps
                address: $address
                #   note: $note
                deliverySlot: $deliverySlot
                deliveryType: $deliveryType
            }
        ) {
            id
            deliveryType {
                enum
            }
        }
    }
`
