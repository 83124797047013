export const SET_MODAL = 'SET_MODAL'
export const SET_PRODUCT_ADDONS = 'SET_ADDONS'
export const SET_PRODUCT_INFO = 'SET_PRODUCT_INFO'
export const DETAIL = 'DETAIL'

export default function reducer(
    state = {
        server: '',
        client: '',
        modal: {
            visible: false,
            type: DETAIL,
            productId: null
        },
        productInfo: {
            wareCategoryId: null,
            promoTag: null
        },
        addons: []
    },
    action
) {
    switch (action.type) {
        case SET_MODAL:
            return { ...state, modal: action.payload }
        case SET_PRODUCT_ADDONS: {
            return { ...state, addons: action.payload }
        }
        case SET_PRODUCT_INFO: {
            return { ...state, productInfo: action.payload }
        }
        default:
            return state
    }
}
