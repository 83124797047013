import { gql } from 'graphql-request'
import { productDetail } from '../fragments/productFragment'

export const promoSingleCategoriesQuery = gql`
    query companyBranches($branchId: ID!) {
        header: wareCategories(
            companyBranch: $branchId
            wareCategoryType: MAIN
            enabled: true
            isAvailable: true
            orderOriginCategory: DOVEZE
            promoTags: [PROMO_HEADER]
        ) {
            id
            nameLabel
            recipes {
                ...productDetail
            }
        }
        footer: wareCategories(
            companyBranch: $branchId
            wareCategoryType: MAIN
            enabled: true
            isAvailable: true
            orderOriginCategory: DOVEZE
            promoTags: [PROMO_FOOTER]
        ) {
            id
            nameLabel
            recipes {
                ...productDetail
            }
        }
    }
    ${productDetail}
`
