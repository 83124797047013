import { gql } from 'graphql-request'

export const createDeliveryOrder = gql`
    mutation createDeliveryOrder($branchId: ID, $brandId: ID, $address: String, $gps: InputGeoPointCreate!) {
        createDeliveryOrder(
            order: { companyBranch: $branchId, brand: $brandId, address: $address, gps: $gps, orderOrigin: MINISITE }
        ) {
            id
        }
    }
`
