import { toast } from 'react-toastify'

function dismissPreviousToast() {
    toast.dismiss()
}

export function showSuccess(text) {
    dismissPreviousToast()
    toast.success(text)
}

export function showInfo(text) {
    dismissPreviousToast()
    toast.info(text)
}

export function showError(text) {
    dismissPreviousToast()
    toast.error(text)
}
