const ServerLoader = () => {
    return (
        <div className={'flex justify-center bg-turbo-background h-screen'}>
            <div className={'h-full justify-center align-middle flex flex-col'}>
                <div
                    className={
                        'font-fk_screamer text-7xl md:text-9xl justify-center flex flex-col md:flex-row items-center'
                    }
                >
                    Na serveru probíhá údržba
                </div>
                <div
                    className={'font-fk_screamer text-5xl justify-center flex flex-col md:flex-row items-center pt-12'}
                >
                    Prosím zkuste později
                </div>
            </div>
        </div>
    )
}
export default ServerLoader
