import {
    SET_USER_DATA,
    SET_USER_ID,
    SET_USER_LOGIN,
    SET_USER_LOGIN_SESSION,
    SET_USER_LOGOUT,
    SET_USER_RESET,
    SET_USER_TOKEN
} from '../reducers/userReducer'

export const setUserLogin = userData => dispatch => {
    return dispatch({
        type: SET_USER_LOGIN,
        payload: userData
    })
}

export const setUserData = userData => dispatch => {
    return dispatch({
        type: SET_USER_DATA,
        payload: userData
    })
}

export const setUserLoginSession = loggedIn => dispatch => {
    return dispatch({
        type: SET_USER_LOGIN_SESSION,
        payload: loggedIn
    })
}

export const setLogoutUser = () => dispatch => {
    return dispatch({
        type: SET_USER_LOGOUT,
        payload: null
    })
}

export const setUserToken = token => dispatch => {
    return dispatch({
        type: SET_USER_TOKEN,
        payload: token
    })
}

export const setUserId = token => dispatch => {
    return dispatch({
        type: SET_USER_ID,
        payload: token
    })
}

export const setUserReset = reset => dispatch => {
    return dispatch({
        type: SET_USER_RESET,
        payload: reset
    })
}
