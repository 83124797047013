import { gql } from 'graphql-request'
import { productDetail } from '../fragments/productFragment'

export const getOrderQuery = gql`
    query order($orderId: ID!) {
        order(id: $orderId) {
            id
            orderStateCategory
            creditsPendingWalletPayment {
                amount {
                    value
                }
                unpaid {
                    value
                }
            }
            isLocked: isPartiallyFinalized
            isPaidWithCard
            deliverAt
            deliverAtWorst
            note

            minimalOrderPrice {
                value
                currency {
                    code
                }
            }

            deliveryType {
                enum
            }
            orderOnlineState {
                enum
            }
            tip {
                value
                formattedValue
                roundedValue
                currency {
                    code
                }
            }
            discounts {
                description
                price {
                    value
                    currency {
                        enum
                        code
                    }
                }
                promoCodes
            }
            orderPayments {
                paymentMethod {
                    paymentGate {
                        enum
                    }
                }
            }
            zone {
                id
                deliveryTimeDeviation
            }
            transferOrderTo {
                id
            }
            transportFee {
                value
                currency {
                    enum
                    code
                }
            }
            coverPrice {
                currency {
                    enum
                    code
                }
                value
            }
            totalSum {
                value
                currency {
                    enum
                    code
                }
            }
            address {
                city
                street
                houseNumber
                postCode
            }
            customer {
                firstName
                lastName
                emails {
                    email
                }
                phones {
                    phone
                }
            }
            user {
                isGuest
            }
            creditsReward {
                amount {
                    formattedValue
                }
                state {
                    enum
                }
            }
            promoCodes {
                id
                code
                type {
                    enum
                    rawCode
                    code
                }
            }

            companyBranch {
                name
                id
                phone
                creditsGroupMembership {
                    canCollect
                    canSpend
                }
            }
            items {
                id
                automatic
                recipe {
                    ...productDetail
                }
                note
                sideDishes {
                    recipe {
                        id
                        nameLabel
                        price {
                            currency {
                                enum
                                code
                            }
                            value
                        }
                    }
                }
                priceWithSideDishes {
                    currency {
                        enum
                        code
                    }
                    value
                }
                price {
                    currency {
                        enum
                        code
                    }
                    value
                }
            }
        }
    }
    ${productDetail}
`
