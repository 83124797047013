import { gql } from 'graphql-request'

export const submitOrderMutation = gql`
    mutation submitOrder(
        $orderId: ID!
        $firstName: String
        $lastName: String
        $emails: [InputEmailCreate!]
        $phones: [InputPhoneCreate!]
        $deliverySlotId: ID!
        $note: String
        $returnUrl: String
        $paymentMethodId: ID
        $confirmedAgreements: [ID!]
    ) {
        finishOrder(
            id: $orderId
            order: {
                customer: { firstName: $firstName, lastName: $lastName, emails: $emails, phones: $phones }
                deliverySlot: $deliverySlotId
                note: $note
                paymentMethodId: $paymentMethodId
                returnUrl: $returnUrl
                confirmedAgreements: $confirmedAgreements
            }
        ) {
            userErrors
            csobUrl
            gopayUrl
            payFastUrl
            payuUrl
        }
    }
`
