import { gql } from 'graphql-request'
import { productDetail } from '../fragments/productFragment'

export const promoCategoriesQuery = gql`
    query companyBranches($branchId: ID!, $promoTags: [PromoTagEnum!]) {
        wareCategories(
            companyBranch: $branchId
            wareCategoryType: MAIN
            enabled: true
            isAvailable: true
            orderOriginCategory: DOVEZE
            promoTags: $promoTags
        ) {
            id
            nameLabel
            recipes {
                ...productDetail
            }
        }
    }
    ${productDetail}
`
